import { firebase } from '@/services/firebase'

const ourRegion = 'asia-northeast1'
const firebaseFunctions = firebase.app().functions(ourRegion)

export const adminNewId = firebaseFunctions.httpsCallable('adminNewId')
export const adminGetAuthUsers = firebaseFunctions.httpsCallable('adminGetAuthUsers')
export const adminGetAdmin = firebaseFunctions.httpsCallable('adminGetAdmin')
export const adminGetUsers = firebaseFunctions.httpsCallable('adminGetUsers')
export const adminCreateUser = firebaseFunctions.httpsCallable('adminCreateUser')
export const adminUpdateUser = firebaseFunctions.httpsCallable('adminUpdateUser')
export const adminGetInterviews = firebaseFunctions.httpsCallable('adminGetInterviews')
export const adminGetResponse = firebaseFunctions.httpsCallable('adminGetResponse')

export const adminCreateReports = firebaseFunctions.httpsCallable('adminCreateReports')
export const adminGetReports = firebaseFunctions.httpsCallable('adminGetReports')

export const adminGetOwners = firebaseFunctions.httpsCallable('adminGetOwners')

export const adminGetQRCodes = firebaseFunctions.httpsCallable('adminGetQRCodes')
export const adminGetQRCodeCounts = firebaseFunctions.httpsCallable('adminGetQRCodeCounts')
export const adminGetMixpanelEvents = firebaseFunctions.httpsCallable('adminGetMixpanelEvents')
